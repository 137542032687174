const routers = [
    {
        path: "/",
        component: (resolve) => require(["../components/index/index.vue"], resolve),
        meta: {
            title: "游戏中心",
        },
    },
    {
        path: "/gameList",
        component: (resolve) => require(["../components/index/gameList.vue"], resolve),
        meta: {
            title: "游戏列表",
        },
    },
    {
        path: "/search",
        component: (resolve) => require(["../components/index/search.vue"], resolve),
        meta: {
            title: "游戏",
        },
    },
    {
        path: "/payMoney",
        component: (resolve) => require(["../components/payMoney/index.vue"], resolve),
        meta: {
            title: "充值",
        },
    },
    {
        path: "*",
        redirect: "/",
    },
];

export default routers;
